import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const loginSessionCheck = WrappedComponent => {
    return props => {
        const navigate = useNavigate();
        const sessionTimeout = 2 * 60 * 60 * 1000; // 예: 1시간
        // const sessionTimeout = 5000; // 테스트 세션 시간 5초


        useEffect(() => {
            const loginTime = parseInt(localStorage.getItem('loginTime'), 10);
            const currentTime = new Date().getTime();

            // 세션 만료 확인
            if (currentTime - loginTime > sessionTimeout) {
                localStorage.removeItem('userID');
                localStorage.removeItem('userName');
                localStorage.removeItem('loginTime');
                navigate('/logout');
            }
        }, [navigate, sessionTimeout]);

        // 세션이 유효하면 원래 컴포넌트 렌더링
        return <WrappedComponent {...props} />;
    };
};

export default loginSessionCheck;