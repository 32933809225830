import React, { useState, useEffect, useRef } from 'react';
import '../styles/Making.scss';
import ArrowButton from '../components/ArrowButton';
import { useNavigate, useLocation, json } from 'react-router-dom';

//NOTE: Internal Dependencies.
import { getBookContents, logStorylandReading } from '../api/LibraryAPI';
import Header from '../components/Header';
import FinalReadingModal from '../components/FinalReadingModal';
import RenderTextWithBreaks from '../components/RenderTextWithBreaks';
import LandScapeModal from '../components/LandScapeModal';
import ErrorModal from '../components/ErrorModal';
import TextToSpeech from '../components/TextToSpeech';
import { ERROR } from '../utils/Constants';

const ReadingbyAIMagkickid = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const USER_ID = queryParams.get('userID');
    const BOOK_ID = queryParams.get('bookID');
    const flag = queryParams.get('flag');

    const [story, setStory] = useState(null);

    const [index, setIndex] = useState(-1);

    const [showFinalReadingModal, setShowFinalReadingModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [bgImage, setbgImage] = useState('');
    const [contentText, setcontentText] = useState([]);

    const [bookCover, setBookCover] = useState('');
    const [bookTitle, setBookTitle] = useState('');

    const [speechState, setSpeechState] = useState(false);

    // const callLogReadingAPIFunc = () => {
    //     const response = logStorylandReading(USER_ID, BOOK_ID);

    //     response.then((res) => {
    //         if (res.success) {
    //             console.log('@@ data =>', res.data);
    //         } else {
    //             console.log(res.error.errMsg);
    //             setErrorMessage(ERROR.lack);
    //             setShowErrorModal(true);
    //         }
    //     });
    // };

    const callBookContentsAPI = async () => {
        let res = await getBookContents(BOOK_ID);
        if (res.success) {
            console.log(res.data)
            setStory(res.data.contents_json.story)
            setbgImage(res.data.bookcover)
            setcontentText(res.data.title)
            setBookCover(res.data.bookcover)
            setBookTitle(res.data.title)
        }
    }

    const callStorylandAPI = async () => {
        let res = await logStorylandReading(BOOK_ID);
        if (res.success) {
            console.log(res.data)
            setStory(res.data.contents_json.story)
            setbgImage(res.data.bookcover)
            setcontentText(res.data.title)
            setBookCover(res.data.bookcover)
            setBookTitle(res.data.title)
        }
    }

    useEffect(() => {
        if (flag == 'storyland') {
            callStorylandAPI()
        }
        else {
            callBookContentsAPI()
        }

        // callLogReadingAPIFunc()
    }, []);

    useEffect(() => {
        if (index === -1) {
            setbgImage(bookCover);
            setcontentText(bookTitle);
            setShowFinalReadingModal(false);
        }
        else {
            setbgImage(story[index].background);
            let contents = story[index].page_content;
            if (contents.includes('\\')) contents = contents.replaceAll('\\', '');
            contents = contents.split('. ');
            if (contents.length > 1) {
                for (let i = 0; i < contents.length - 1; i++) {
                    contents[i] = contents[i] + '.';
                }
            }
            setcontentText(contents);

            console.log('contentText: ' + contents);
        }

    }, [index]); // []만 쓰면 한번만 호출, [index]면 index가 변할때마다 호출

    const handleButtonClick = (direction) => {
        setIndex((prevIndex) => {
            let newIndex = prevIndex + (direction === 'left' ? -1 : 1);
            if (newIndex < -1) {
                newIndex = story.length - 1;
            } else if (newIndex >= story.length) {
                setShowFinalReadingModal(true);
                return prevIndex;
            }
            console.log('@@ new index => ', newIndex);
            return newIndex;
        });
    };
    // 버튼 클릭 이벤트 전체

    const handleSpeechState = () => {
        if (speechState) {
            setSpeechState(false)
        }
        else {
            setSpeechState(true)
        }

    }

    return (
        <>
            <section className="stories-section">
                {showErrorModal && <ErrorModal
                    error_message={errorMessage}
                    onClose={() => window.close()}
                ></ErrorModal>
                }
                <figure className="stories-bg">
                    <img
                        src={bgImage}
                        alt="bg-img"
                    />
                </figure>
                {index !== -1 && (
                    <ArrowButton
                        onClick={() => handleButtonClick('left')}
                        direction="left"
                        className="story-btn prev-btn"
                    />
                )}
                <ArrowButton
                    onClick={() => handleButtonClick('right')}
                    direction="right"
                    className="story-btn next-btn"
                />
                <button className='back-mkid' onClick={() => { window.close(); }}> AI매직포유로 돌아가기 </button>
                <ul className={typeof (contentText) === 'string' ? 'story-contents title' : 'story-contents'}>


                    {typeof (contentText) === 'string' ?
                        <>
                            {contentText}
                            <div className='text-stroke'>{contentText}</div>
                        </> :
                        <>
                            {contentText.map((sentence, i) => (
                                <li
                                    key={i}
                                    className="origin-text"
                                >
                                    {sentence.trim()}
                                    <div className="text-stroke">{sentence.trim()}</div>
                                </li>
                            ))}
                        </>
                    }
                </ul>
                <TextToSpeech
                    text={contentText}
                    speechState={speechState}
                    onChange={handleSpeechState}
                />
                <div className="numbering">{index + 1}</div>
                {showFinalReadingModal && (
                    <FinalReadingModal
                        onBack={() => setIndex(-1)}
                        pageName='aimagickid'
                        onClose={() => {
                            setShowFinalReadingModal(false);
                        }}
                    />
                )}
            </section >
            <LandScapeModal />
        </>
    );
};

export default ReadingbyAIMagkickid;
