import React, { useState, useEffect } from 'react';
import '../styles/MyStorybook.scss';
import BookItem from '../components/BookItem';
import loginSessionCheck from '../components/LoginSessionCheck';
import { useNavigate } from 'react-router-dom';

//NOTE: Internal Dependencies.
import { getMybookList } from '../api/LibraryAPI';
import Floating from '../components/Floating';
import LandScapeModal from '../components/LandScapeModal';

const MyStorybook = () => {
    const USER_ID = localStorage.getItem('userID');

    const [bookList, setbookList] = useState([]);

    const navigate = useNavigate();
    const goStoryPage = () => {
        navigate('/selecting');
    };

    useEffect(() => {
        callMybookListAPIFunc(USER_ID);
    }, [USER_ID]); // []만 쓰면 한번만 호출, [index]면 index가 변할때마다 호출

    //NOTE: 내 책 리스트 조회 API 호출 함수
    const callMybookListAPIFunc = (param) => {
        const response = getMybookList(param);

        response.then((res) => {
            if (res.success) {
                console.log('@@ data =>', res.data);
                setbookList(res.data);
            } else {
                console.log(res.error.errMsg);
            }
        });
    };

    const handleGoChainTree = () => {
        window.open(
            `https://chaintree.biz`,
            '_blank',
            'noopener,noreferrer'
        );
    };

    return (
        <>
            <section className="book-list-section">
                <div className="inner">
                    <div className="library-title">
                        <h3>나만의 서재 📗</h3>
                        <p
                            className="make-story-btn"
                            onClick={() => goStoryPage()}
                        >
                            만들기 +
                        </p>
                    </div>
                    <ul className="book-list">
                        <BookItem
                            bookList={bookList}
                            pageName="myStorybook"
                        />
                    </ul>
                </div>
                <figure className="book-bottom">
                    <img
                        src="/images/book-bottom-2.png"
                        alt="book bottom item"
                    />
                </figure>
                {/* 2024-02-14 | footer-txt 추가 */}
                <div className="footer-txt">
                    <p className="site" onClick={handleGoChainTree} >www.chaintree.biz</p>
                    <p>© Copyright ChainTree Inc. All Rights Reserved</p>
                </div>
                <Floating />
            </section>
            <LandScapeModal />
        </>
    );
};

export default loginSessionCheck(MyStorybook);
