import axios from 'axios';

export const performRequest = async (method, url, data = null) => {
    try {
        const config = {
            method: method,
            url: url,
            ...(data && { data: data }),
        };
        const response = await axios(config);

        if (response.status === 200 || response.status === 201) {
            return {
                success: response.data.success,
                data: response.data.data,
                error: '',
            };
        } else {
            return {
                success: response.data.success,
                error: response.data.data,
            };
        }
    } catch (err) {
        return {
            success: false,
            error: err.response ? err.response.data : err.message,
        };
    }
};
