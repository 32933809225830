import { performRequest } from './utils/RequestUtils';
import { STORY_ENDPOINT } from './utils/EndPoint';

export const getStoryList = async (user_id) => {
  return performRequest('get', `${STORY_ENDPOINT}/list/${user_id}`);
};

export const getStoryInfo = async (data) => {
  return performRequest('get', `${STORY_ENDPOINT}/contents/${data.story_id}`);
};

export const callChatGPTAPIforStory = async (data) => {
  return performRequest('post', `${STORY_ENDPOINT}/chatgpt/contents`, data);
};

export const callChatGPTAPIforBackground = async (data) => {
  return performRequest('post', `${STORY_ENDPOINT}/chatgpt/background`, data);
};

// export const callFalaiAPIforBackground = async (data) => {
//   return performRequest('post', `${STORY_ENDPOINT}/falai/background`, data);
// };

// export const callFalaiAPIforBackground = async (
//   story_id, data, user_id, usage
// ) => {
//   return performRequest(
//     'post',
//     `${STORY_ENDPOINT}/falai/background`,
//     {
//       story_id: story_id ?? '',
//       prompts: data ?? '',
//       user_id: user_id ?? '',
//       usage: usage ?? '',
//     }
//   );
// };

export const callFalaiAPIforBackground = async (story_id, data, user_id, usage) => {
  const payload = {
    story_id: story_id ?? '',
    prompts: data ?? '',
    user_id: user_id ?? '',
    usage: usage ?? '',
  };
  return performRequest('post', `${STORY_ENDPOINT}/falai/background`, payload);
};

export const callChatGPTAPIforTitle = async (data) => {
  return performRequest('post', `${STORY_ENDPOINT}/chatgpt/title`, data);
};

export const callChatGPTAPIforBookcover = async (data) => {
  return performRequest('post', `${STORY_ENDPOINT}/chatgpt/bookcover`, data);
};

export const saveCompletedBook = async (data) => {
  return performRequest('post', `${STORY_ENDPOINT}/book`, data);
};

export const getCheckBranches = async (data) => {
  return performRequest('get', `${STORY_ENDPOINT}/${data.story_id}/page/check`);
};

export const getPageContents = async (data) => {
  return performRequest('get', `${STORY_ENDPOINT}/${data.story_id}/page/${data.page_id}`);
};

export const getPageBranch = async (data) => {
  return performRequest('get', `${STORY_ENDPOINT}/${data.story_id}/page/${data.page_id}/branch/${data.path}`);
};

export const getBookContents = async (data) => {
  return performRequest('get', `${STORY_ENDPOINT}/book/contents/${data.book_id}`);
};

export const callMidjourneyAPI = async (data) => {
  return performRequest('post', `${STORY_ENDPOINT}/midjourney`, data);
};

export const getMidjourneyAPI = async (data) => {
  return performRequest('get', `${STORY_ENDPOINT}/midjourney/${data.image_id}/`);
};
