import React, { useState, useEffect } from 'react';
import '../styles/BranchModal.scss';


const BranchModal = ({ onChoice, onClose, choiceData }) => {
    console.log('choice data', choiceData);

    const [selectedOption, setSelectedOption] = useState(null);
    const [tts, setTTS] = useState(null);
    const { title, options } = choiceData;

    const addLineBreaks = (text) => {
        const lines = text.split(/,|\./);
        return lines.map((line, index) => <p key={index}>{line.trim()}</p>);
    };

    const handleChooseClick = (option) => {
        setTTS(option.title); //NOTE: 2024-04-08 추후에 description으로 변경 고민
        setSelectedOption(option.description);
    };

    const handleConfirmChoice = () => {
        if (selectedOption !== null) {
            onChoice(selectedOption);
            onClose();
        }
    };

    const handleGoBack = () => {
        onClose();
    };

    useEffect(() => {
        const synth = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(tts);

        // 새로운 텍스트로 음성 합성을 시작합니다.
        if (tts) {
            synth.cancel(); // 현재 진행 중인 음성 합성을 중지합니다.
            synth.speak(utterance); // 새로운 텍스트로 음성 합성을 시작합니다.
        }

        return () => {
            synth.cancel(); // 컴포넌트가 언마운트되거나 text가 변경될 때, 음성 합성을 중지합니다.
        };
    }, [tts]);

    return (
        <>
            <section className="choice-section">
                <div
                    className="go-back"
                    onClick={handleGoBack}
                >
                    뒤로가기
                </div>
                <div className="inner">
                    <figure
                        className="close-modal-btn"
                        onClick={onClose}
                    >
                        <img
                            src={'/images/close.png'}
                            alt=""
                        />
                    </figure>
                    <div className="question-container">
                        <div className="origin-text">
                            {addLineBreaks(title)}
                            <div className="text-stroke">
                                {addLineBreaks(title)}
                            </div>
                        </div>
                    </div>
                    <div className="choice-btn-container">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={`choice choice-${index + 1} ${selectedOption === option.description
                                    ? 'border'
                                    : ''
                                    }`}
                                onClick={() => handleChooseClick(option)}
                            >
                                <figure>
                                    <img
                                        src={option.image}
                                        alt={option.title}
                                    />
                                </figure>
                                <h3>{option.title}</h3>
                            </div>
                        ))}
                    </div>
                    {selectedOption && (
                        <button
                            className="confirm-choice-btn"
                            onClick={handleConfirmChoice}
                        >
                            스토리 만들기
                        </button>
                    )}
                </div>
            </section>
        </>
    );
};

export default BranchModal;
