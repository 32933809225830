import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

const HeaderController = () => {
    const location = useLocation();
    const shouldHideHeader = location.pathname === '/reading-aimagickid';

    if (shouldHideHeader) {
        return null;
    }

    return <Header />;
};

export default HeaderController;
