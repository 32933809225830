import React, { useState, useEffect, useRef } from 'react';
import '../styles/Making.scss';
import ArrowButton from '../components/ArrowButton';
import { useNavigate, useLocation } from 'react-router-dom';

//NOTE: Internal Dependencies.
import { getBookContents } from '../api/StoryAPI';
import Header from '../components/Header';
import FinalReadingModal from '../components/FinalReadingModal';
import RenderTextWithBreaks from '../components/RenderTextWithBreaks';
import TextToSpeech from '../components/TextToSpeech';
import LandScapeModal from '../components/LandScapeModal';
import { logStorylandReading } from '../api/LibraryAPI';
import ErrorModal from '../components/ErrorModal';
import { ERROR } from '../utils/Constants';

const Reading = () => {
    const USER_ID = localStorage.getItem('userID');
    const location = useLocation();
    const { bookList, pageName, bookID } = location.state;

    const story = bookList.contents_json.story;

    const [index, setIndex] = useState(-1);

    const [showFinalReadingModal, setShowFinalReadingModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [bgImage, setbgImage] = useState('');
    const [contentText, setcontentText] = useState([]);

    const [speechState, setSpeechState] = useState(false);

    const navigate = useNavigate();

    console.log('bookID: ', bookID);

    useEffect(() => {
        if (pageName === 'storyland') {
            callLogReadingAPIFunc()
        }
    }, []);

    const callLogReadingAPIFunc = () => {
        const response = logStorylandReading(USER_ID, bookID);

        response.then((res) => {
            if (res.success) {
                console.log('@@ data =>', res.data);

            } else {
                console.log(res.error.errMsg);
                console.log(res.error.errMsg);
                setErrorMessage(ERROR.lack);
                setShowErrorModal(true);

            }
        });
    };


    useEffect(() => {
        if (index === -1) {
            setbgImage(bookList.bookcover);
            setcontentText(bookList.title);
            setShowFinalReadingModal(false);
        } else {
            setbgImage(story[index].background);
            let contents = story[index].page_content;
            if (contents.includes('\\'))
                contents = contents.replaceAll('\\', '');
            contents = contents.split('. ');
            if (contents.length > 1) {
                for (let i = 0; i < contents.length - 1; i++) {
                    contents[i] = contents[i] + '.';
                }
            }
            setcontentText(contents);

            console.log('contentText: ' + contents);
        }
    }, [index]); // []만 쓰면 한번만 호출, [index]면 index가 변할때마다 호출

    const handleButtonClick = (direction) => {
        setIndex((prevIndex) => {
            let newIndex = prevIndex + (direction === 'left' ? -1 : 1);
            if (newIndex < -1) {
                newIndex = story.length - 1;
            } else if (newIndex >= story.length) {
                setShowFinalReadingModal(true);
                return prevIndex;
            }
            console.log('@@ new index => ', newIndex);
            return newIndex;
        });
    };

    const handleSpeechState = () => {
        if (speechState) {
            setSpeechState(false)
        }
        else {
            setSpeechState(true)
        }

    }

    return (
        <>
            <section className="stories-section">
                {showErrorModal && <ErrorModal
                    error_message={errorMessage}
                    onClose={() => {
                        setShowErrorModal(false);
                        navigate('/storyland');
                    }}
                ></ErrorModal>
                }
                <figure className="stories-bg">
                    <img
                        src={bgImage}
                        alt="bg-img"
                    />
                </figure>
                {index !== -1 && (
                    <ArrowButton
                        onClick={() => handleButtonClick('left')}
                        direction="left"
                        className="story-btn prev-btn"
                    />
                )}
                <ArrowButton
                    onClick={() => handleButtonClick('right')}
                    direction="right"
                    className="story-btn next-btn"
                />
                <ul
                    className={
                        typeof contentText === 'string'
                            ? 'story-contents title'
                            : 'story-contents'
                    }
                >
                    {/* <li
                        className="origin-text"
                    >
                        {contentText.length !==0 && <RenderTextWithBreaks text={contentText}/> }
                        <div className="text-stroke">{contentText.length !==0 && <RenderTextWithBreaks text={contentText}/> }</div>
                    </li> */}

                    {typeof contentText === 'string' ? (
                        <>
                            {contentText}
                            <div className="text-stroke">{contentText}</div>
                        </>
                    ) : (
                        <>
                            {contentText.map((sentence, i) => (
                                <li
                                    key={i}
                                    className="origin-text"
                                >
                                    {sentence.trim()}
                                    <div className="text-stroke">
                                        {sentence.trim()}
                                    </div>
                                </li>
                            ))}
                        </>
                    )}
                </ul>
                {/* 2024-02-06 | TextToSpeech 컴포넌트 위치 변경 */}
                <TextToSpeech
                    text={contentText}
                    speechState={speechState}
                    onChange={handleSpeechState}
                />
                <div className="numbering">{index + 1}</div>
                {showFinalReadingModal && (
                    <FinalReadingModal
                        onBack={() => setIndex(-1)}
                        pageName={pageName}
                        onClose={() => {
                            setShowFinalReadingModal(false);
                        }}
                    />
                )}
            </section>
            <LandScapeModal />
        </>
    );
};

export default Reading;
