import React, { useState, useEffect } from 'react';
import '../styles/Selecting.scss';
import { useNavigate, useLocation } from 'react-router-dom';

//NOTE: Internal Dependencies.
import { useSelectedStoryID } from '../SelectedBookContext';
import SelectingList from '../components/SelectingList';
import { getStoryList } from '../api/StoryAPI';
import { getTotalMagicbean } from '../api/MagicbeanAPI';
import Floating from '../components/Floating';
import loginSessionCheck from '../components/LoginSessionCheck';
import LandScapeModal from '../components/LandScapeModal';
import ErrorModal from '../components/ErrorModal';
import { ERROR } from '../utils/Constants';

const Selecting = () => {
    const USER_ID = localStorage.getItem('userID');
    const tabMenu = [
        { id: 'all', label: '전체' },
        { id: 'comic', label: '재미있는 창작동화' },
        { id: 'education', label: '교훈을 주는 창작동화' },
    ];
    const { setStoryId } = useSelectedStoryID(); //NOTE: 선택된 책의 id 값 관리(상태를 전역으로 관리할 수 있음)
    const [storyList, setStoryList] = useState([]);
    // 2024.01.04 | 기본 상태 comic으로 변경
    const [activeTab, setActiveTab] = useState('all');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    console.log('storyList', storyList);

    const navigate = useNavigate();

    useEffect(() => {
        callStoryListAPIFunc();
    }, [activeTab]);

    //NOTE: 전체 스토리 리스트 조회 API 호출 함수
    const callStoryListAPIFunc = () => {
        const response = getStoryList(USER_ID);

        response.then((res) => {
            if (res.success) {
                console.log('@@ data =>', res.data);
                // 2024.01.04 | setStoryList activeTab 변경 (comic,education으로)
                setStoryList(() => {
                    if (activeTab === 'all') {
                        const filteredStory = res.data.filter(
                            (item) =>
                                item.category === 'comic' ||
                                item.category === 'education'
                        );
                        return filteredStory;
                    } else if (activeTab === 'comic') {
                        const filteredStory = res.data.filter(
                            (item) => item.category === 'comic'
                        );
                        return filteredStory;
                    } else if (activeTab === 'education') {
                        const filteredStory = res.data.filter(
                            (item) => item.category === 'education'
                        );
                        return filteredStory;
                    }
                });
            } else {
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
                console.log(res.error.errMsg);
            }
        });
    };

    const handleBookSelect = (selectedBook) => { //NOTE: 마법콩 확인 필요! 잔여 api호출
        console.log('FairyTaleList -> FairyTale로 전달된 book', selectedBook);
        setStoryId(selectedBook.story_id);
        if (selectedBook.description != '수정 필요')
            navigate(`/making/${selectedBook.story_id}`);
        else alert('해당 동화는 준비중입니다.');
    };

    const handleChooseClick = (selectedBook) => {
        console.log('선택된 story_id', selectedBook.story_id);
        console.log('@@@@ selectedBook', selectedBook);
        setStoryId(selectedBook.story_id);
        if (selectedBook.description != '수정 필요')
            navigate(`/making/${selectedBook.story_id}`);
        else alert('해당 동화는 준비중입니다.');
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleGoChainTree = () => {
        window.open(
            `https://chaintree.biz`,
            '_blank',
            'noopener,noreferrer'
        );
    };


    return (
        <>
            <section className="fairy-tale-section">
                {showErrorModal && <ErrorModal error_message={errorMessage} onClose={() => setShowErrorModal(false)} />}
                <div className="inner">
                    <div className="title-container">
                        <h3>어떤 동화를 만들까요?</h3>
                        <p>만들고싶은 동화를 선택해주세요.</p>
                    </div>
                    <ul className="tab-list">
                        {tabMenu.map((tab) => (
                            <li
                                key={tab.id}
                                className={activeTab === tab.id ? 'active' : ''}
                                onClick={() => handleTabChange(tab.id)}
                            >
                                {tab.label}
                            </li>
                        ))}
                    </ul>
                    <ul className="fairy-tale-list">
                        <SelectingList
                            bookData={storyList}
                            // FairyTaleList에 책 데이터 넘겨주기
                            onBookSelect={handleBookSelect}
                            // 사용자가 FairyTaleList에서 "선택하기" 버튼을 눌렀을떄 실행되는 함수
                            onChooseClick={handleChooseClick}
                        // 사용자가 PlotModal에서 "선택하기" 버튼을 눌렀을때 실행되는 함수
                        />
                        {/* 책 이미지(book-cover-list), 책 제목(book-title), story_id 넘기기 */}
                    </ul>
                </div>
                <figure className="bottom">
                    <img
                        src="/images/bottom-pink.svg"
                        alt="fairy-tale-bottom"
                    />
                </figure>
                {/* 2024-02-14 | footer-txt 추가 */}
                <div className="footer-txt">
                    <p className="site" onClick={handleGoChainTree} >www.chaintree.biz</p>
                    <p>© Copyright ChainTree Inc. All Rights Reserved</p>
                </div>
                <Floating />
            </section>
            <LandScapeModal />
        </>
    );
};

export default loginSessionCheck(Selecting);
