// import React, { useState, useEffect } from 'react';
// import '../styles/ProgressBar.scss';

// const ProgressBar = ({ onProgressComplete }) => {
//   // onProgressComplete props 받기
//   const [progress, setProgress] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (progress < 100) {
//         setProgress((prevProgress) => prevProgress + 1);
//       } else {
//         onProgressComplete();
//       }
//       // else 추가
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [progress, onProgressComplete]);
//   // onProgressComplete 추가

//   return (
//     <div className="progress-bar">
//       <div className="progress" style={{ width: `${progress}%` }}>
//         {progress}%
//       </div>
//     </div>
//   );
// };

// export default ProgressBar;

import React, { useState, useEffect } from 'react';
import '../styles/ProgressBar.scss';

const ProgressBar = ({ onProgressComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 99) {
          clearInterval(interval);
          return prevProgress; // 99% 이상에서는 증가하지 않음
        }
        if (prevProgress < 50) {
          return prevProgress + 3; // 60% 미만일 때 5%씩 증가
        } else if (prevProgress < 70) {
          return prevProgress + 2; // 60% 이상 80% 미만일 때 2%씩 증가
        } else if (prevProgress < 90) {
          return prevProgress + 1; // 80% 이상 90% 미만일 때 1%씩 증가
        } else {
          return prevProgress + 0.1; // 90% 이상에서는 천천히 증가
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      onProgressComplete();
    }
  }, [progress, onProgressComplete]);

  return (
    <div className="progress-bar">
      <div className="progress" style={{ width: `${progress}%` }}>
        {Math.floor(progress)}%
      </div>
    </div>
  );
};

export default ProgressBar;