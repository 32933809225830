import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyles = createGlobalStyle`
    ${reset}

    *{ box-sizing:border-box;}
    *::-webkit-scrollbar {width: 8px; border-radius: 10px;}
    *::-webkit-scrollbar-thumb {height: 30%;background: #2FCC59; border-radius: 10px;}
    *::-webkit-scrollbar-track {background: rgba(33, 122, 244, .1); border-radius: 10px;}
    a{ text-decoration: none; color:initial;}
    body{font-family: 'EASTARJET'; letter-spacing: -1px; word-break: keep-all; color: initial; cursor: url('../../images/wand-blue.svg'), auto !important;}
    img{ display:block; width:100%; }

    :root{
        --bs-blue: #0d6efd;
        --bs-indigo: #6610f2;
        --bs-purple: #6f42c1;
        --bs-pink: #d63384;
        --bs-red: #dc3545;
        --bs-orange: #fd7e14;
        --bs-yellow: #FDBF6C;
        --bs-green-primary: #005E43;
        --bs-green-secondary: #00AA83;
        --bs-green-light: #008D69;
        --bs-teal: #20c997;
        --bs-cyan: #0dcaf0;
        --bs-white: #fff;
        --bs-gray: #6c757d;
        --bs-gray-dark: #343a40;
        --bs-gray-100: #f8f9fa;
        --bs-gray-200: #e9ecef;
        --bs-gray-300: #dee2e6;
        --bs-gray-400: #ced4da;
        --bs-gray-500: #adb5bd;
        --bs-gray-600: #6c757d;
        --bs-gray-700: #495057;
        --bs-gray-800: #343a40;
        --bs-gray-900: #212529;
        --bs-primary: #326bff;
        --bs-primary-dark: #151f35;
        --bs-primary-light: #afc5ff;
        --bs-primary-hover: #1854ee;
        --bs-dark: #191919;
        --bs-body-color: #191919;
        --bs-bg-blue: #edf1ff;
        --bs-gray: #797979;
        --bs-gray-100: #f5f5f5;
        --bs-gray-200: #eee;
        --bs-gray-300: #ddd;
        --bs-gray-400: #bbb;
        --bs-gray-500: #aaa;
        --bs-border: #bdbdbd;
        --bs-border-blue: #3e4e79;
        --bs-light-blue: #8dadff;
        --bs-thin-blue: #e3ebff;
        --bs-light: #f0f3f8;
        --bs-yellow: #ffe609;
        --bs-black: #000;
        --bs-primary-rgb: 50,107,255;
        --bs-primary-dark-rgb: 21,31,53;
        --bs-primary-light-rgb: 175,197,255;
        --bs-primary-hover-rgb: 24,84,238;
        --bs-dark-rgb: 25,25,25;
        --bs-body-color-rgb: 25,25,25;
        --bs-bg-blue-rgb: 237,241,255;
        --bs-gray-rgb: 121,121,121;
        --bs-gray-100-rgb: 245,245,245;
        --bs-gray-200-rgb: 238,238,238;
        --bs-gray-300-rgb: 221,221,221;
        --bs-gray-400-rgb: 187,187,187;
        --bs-gray-500-rgb: 170,170,170;
        --bs-border-rgb: 189,189,189;
        --bs-border-blue-rgb: 62,78,121;
        --bs-light-blue-rgb: 141,173,255;
        --bs-thin-blue-rgb: 227,235,255;
        --bs-light-rgb: 240,243,248;
        --bs-yellow-rgb: 255,230,9;
        --bs-white-rgb: 255,255,255;
        --bs-black-rgb: 0,0,0;
        --bs-body-color-rgb: 33,37,41;
        --bs-body-bg-rgb: 255,255,255;
        --bs-font-sans-serif: "Gilroy","Noto Sans KR",-apple-system,BlinkMacSystemFont,"Helvetica Neue","Apple SD Gothic Neo",Arial,sans-serif;
        --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
        --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
        --bs-body-font-family: var(--bs-font-sans-serif);
        --bs-body-font-size: 1rem;
        --bs-body-font-weight: 400;
        --bs-body-line-height: 1.5;
        --bs-body-color: #212529;
        --bs-body-bg: #fff
        
    }

    .inner{ max-width: 1280px; /* max-width: 1360px; */ /* width: calc(100% - 400px); */ margin: 0 auto; position: relative;}
`;