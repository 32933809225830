import React from 'react';
import '../styles/ExpiredModal.scss';

const ExpiredModal = () => {

    const handleButton = () => {
        window.close();
    }
    return (
        <>
            <section className="expired-section">
                <div className="inner">
                    <figure>
                        <img
                            src={'/images/expired.svg'}
                            alt="wizard"
                        />
                    </figure>
                    <div className="expired-container">
                        <p>로그인이 만료되었어요! 다시 로그인하러 갈까요?</p>
                        <button onClick={handleButton}>AI매직포유 로그인 하러가기</button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ExpiredModal;