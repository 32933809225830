import React, { useEffect, useState, useRef } from 'react';
import '../styles/Making.scss';
import ArrowButton from '../components/ArrowButton';
import FinalModal from '../components/FinalModal';
import { useParams } from 'react-router-dom';
import BranchModal from '../components/BranchModal';
import BackgroundModal from '../components/BackgroundModal';
import InfoModal from '../components/InfoModal';
import BookLoading from '../components/BookLoading';
import ConfirmModal from '../components/ConfirmModal';
import loginSessionCheck from '../components/LoginSessionCheck';
import LandScapeModal from '../components/LandScapeModal';
import BackwardModal from '../components/BackwardModal';
import TextToSpeech from '../components/TextToSpeech';
import Constants from '../utils/Constants';
import {
    getStoryInfo,
    callChatGPTAPIforStory,
    callFalaiAPIforBackground,
    callChatGPTAPIforBackground,
} from '../api/StoryAPI';

const Making = () => {
    const USER_ID = localStorage.getItem('userID');

    const { selectedBookId } = useParams();
    // const { setBookId } = useSelectedBook();
    console.log('selected Book ID', selectedBookId);
    // selectedBookId 넘어온 값

    useEffect(() => {
        const params = {
            story_id: selectedBookId,
        };
        callStoryInfoAPIFunc(params);
    }, [selectedBookId]);

    const pageEndCount = 9;
    // 책 끝 페이지
    // NOTE: state
    const [story, setStory] = useState([{}]); // api 호출 후 pre story 정보 저장 {page_id, page_content, background}
    const [storyTitle, setStoryTitle] = useState();
    const [branchInfo, setBranchInfo] = useState({}); // 분기점 정보 저장 {branchNum, title, options}
    const [pageCount, setPageCount] = useState(0); // 해당 스토리의 총 페이지 수

    const [showLoading, setShowLoading] = useState(false);

    const [loadingTitle, setLoadingTitle] = useState('');
    const [loadingTime, setLoadingTime] = useState(100);

    const [loadingFlag, setLoadingFlag] = useState('');
    const [generateEnd, setGenerateEnd] = useState(false);
    const [regenerateImage, setRegenerateImage] = useState([]);
    const [showInfoModal, setShowInfoModal] = useState(true); //경고 알림화면
    const [index, setIndex] = useState(0); // 데이터 인덱스
    const [showFinalModal, setShowFinalModal] = useState(false);

    // 2023.11.28 추가된 state-------------------------------------------
    const [showBranchModal, setShowBranchModal] = useState(false); //BranchModal 열고닫기
    const [showChoiceBtns, setShowChoiceBtns] = useState(false); //choiceBtn show,hidden 설정
    const [showImageModal, setShowImageModal] = useState(false); // 이미지모달 열고닫기
    const [showConfirmModal, setShowConfirmModal] = useState(false); //ConfirmModal 열고닫기
    const [showBackwardModal, setShowBackwardModal] = useState(false); //BackwardModal 열고닫기
    const [contentText, setcontentText] = useState([]);
    const [speechState, setSpeechState] = useState(false);

    // 오디오 요소에 대한 참조 생성
    const audioRef = useRef(null);

    const controlLoading = (on, flag) => {
        if (on) {
            if (flag === 'story') setLoadingTime(100);
            else if (flag === 'reBg') setLoadingTime(30);
            setGenerateEnd(false);
            setShowLoading(true);
            setShowConfirmModal(false);
        } else {
            setGenerateEnd(true);
            setShowLoading(false);
            setShowConfirmModal(true);
            setSpeechState(false);
        }
    };

    const handleSpeechState = () => {
        if (speechState) {
            setSpeechState(false);
        } else {
            setSpeechState(true);
        }
    };

    //NOTE: 책 내용 조회 API 호출 함수
    const callStoryInfoAPIFunc = (param) => {
        const response = getStoryInfo(param);

        response.then((res) => {
            if (res.success) {
                console.log('@@ data =>', res.data);
                setStory(res.data.contents_json.story);
                setStoryTitle(res.data.title);
                let branchInfo = {
                    branchNum: res.data.contents_json.branchNum,
                    title: res.data.contents_json.branchTitle,
                    options: res.data.contents_json.branchOptions,
                };
                setBranchInfo(branchInfo);
                setPageCount(res.data.page_count);
            } else {
                console.log(res.error.errMsg);
            }
        });
    };

    const handleButtonClick = (direction) => {
        if (direction === 'right' && index === branchInfo.branchNum - 1) {
            setShowBranchModal(true);
            return;
        }

        if (direction === 'left' && index === branchInfo.branchNum) {
            setShowBackwardModal(true);
            return;
        }

        setIndex((prevIndex) => {
            console.log(`prevIndex: ${prevIndex}`);
            let newIndex = prevIndex + (direction === 'left' ? -1 : 1);
            if (newIndex < 0) {
                newIndex = pageCount - 1;
            }
            if (newIndex === pageEndCount + 1) {
                setShowFinalModal(true);
                newIndex = pageEndCount;
            }
            console.log(`newIndex: ${newIndex}`);
            return newIndex;
        });

        console.log(`index: ${index}`);
    };

    const handleBackward = () => {
        setStory((preStroy) => {
            preStroy.splice(branchInfo.branchNum);
            return preStroy;
        });
        setIndex(index - 1);
        setShowBackwardModal(false);
        setShowChoiceBtns(false);
    };

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
        setSpeechState(true);
    };

    // 마침표에 따라서 split하기 (문장 단위로 줄바꿈하기)
    // const sentences = (story[index].page_content ?? '').split('\n');

    const sentences =
        story[index] && story[index].page_content
            ? story[index].page_content.split('\n')
            : [];

    const handleChoice = async (choice) => {
        console.log('사용자의 선택', choice);

        // setSelectedChoice(choice);
        setShowBranchModal(false);
        setShowChoiceBtns(true);
        setLoadingTitle({
            story: ` ${pageCount - branchInfo.branchNum
                }장의 새로운 스토리를 만드는 중`,
            bg: `${pageCount - branchInfo.branchNum
                }장의 새로운 배경그림을 만드는 중`,
        });

        setLoadingFlag('story');
        controlLoading(true, 'story');

        let generatedStory = await callAPIChatGPTforStory(choice);
        try {
            // generatedStory가 문자열이면 JSON.parse를 시도합니다.
            if (typeof generatedStory === 'string') {
                generatedStory = JSON.parse(generatedStory);
            }

            // generatedStory가 배열인지 확인합니다.
            if (Array.isArray(generatedStory)) {
                await callAPIFalaiforBackground(generatedStory);
            } else {
                console.error('generatedStory는 배열이 아닙니다.');
                alert('스토리 생성에 문제가 발생했습니다. 다시 시도해주세요.');
                setShowLoading(false);
                setShowBranchModal(true);
            }
        } catch (error) {
            // JSON.parse에서 오류가 발생한 경우
            console.error('JSON 파싱 오류:', error);
            alert('스토리 생성에 문제가 발생했습니다. 다시 시도해주세요.');
            setShowLoading(false);
            setShowBranchModal(true);
        }
    };

    const handleBackBranchModal = () => {
        setShowBranchModal(false);
        setStory((preStroy) => {
            preStroy.splice(branchInfo.branchNum);
            return preStroy;
        });
    };

    const handleReSelect = () => {
        // setButtonClickCount(4); // 버튼 클릭 카운트 4로 설정 (분기점에서 모달창 떳을때 기준으로)
        //here
        //NOTE: 이전에 생성된 story들 지우기
        setStory((preStroy) => {
            preStroy.splice(branchInfo.branchNum);
            return preStroy;
        });
        setIndex(branchInfo.branchNum - 1); // 분기점 페이지로 이동
        setShowBranchModal(true); // 선택 모달창 열기
        setShowChoiceBtns(false); // 다시 선택하기버튼, 이미지바꾸기 버튼 안보임
    };
    // 다시 선택하기 버튼 클릭시

    const handleChangeBackground = async () => {
        // NOTE: 배경재생성을 위하여 1. ChatGPT API 호출을 통한 배경 묘사 재생성 2. Fal.ai API 호출을 통한 배경 그림 생성
        setLoadingTitle('새로운 배경그림을 만드는 중');
        setLoadingFlag('title');
        controlLoading(true, 'reBg');

        let body = {
            user_id: USER_ID,
            story_id: selectedBookId,
            stories: await getAllStoryContent(),
            now_content: story[index],
        };
        let resChatGPT = await callChatGPTAPIforBackground(body);
        console.log(resChatGPT);
        if (resChatGPT.success) {
            let prompts = JSON.parse(resChatGPT.data);
            await regenerateBackground(prompts);
        } else {
            controlLoading(false);
            alert('배경그림 재생성에 문제가 발생했습니다. 다시 시도해주세요.');
        }
    };
    // 이미지모달 열기

    const handleBackgroundImageChange = (selectedImage) => {
        console.log('선택된 이미지:', selectedImage);
        // setData((prevData) => {
        //   const newData = [...prevData];
        //   newData[index].image = selectedImage;
        //   return newData;
        // });
        setStory((preStroy) => {
            preStroy[index].background = selectedImage;
            return preStroy;
        });
        setShowImageModal(false);
    };
    // 2023.11.28 추가 | ImageModal -> Stories로 전달된 image
    // 이미지 선택하면 해당하는 이미지로 배경 이미지 변경

    const handleAlertConfirm = () => {
        console.log('click');
        setShowInfoModal(false);
    };

    const getAllStoryContent = async () => {
        let stories = '';
        for (const content of story) {
            stories = stories + ' ' + content.page_content;
        }
        return stories;
    };

    const callAPIChatGPTforStory = async (selectedBranch) => {
        // let stories = ''
        // for (const content of story){
        //   stories = stories + ' ' + content.page_content
        // }
        let param = {
            user_id: USER_ID,
            story_id: selectedBookId,
            selectedBranch: selectedBranch,
            stories: await getAllStoryContent(),
        };
        console.log('@@ param => ', param);
        let res = await callChatGPTAPIforStory(param);
        if (res.success) {
            const jsonPart = res.data.match(/```json\s*([\s\S]*?)\s*```/);
            let jsonData;

            if (jsonPart && jsonPart[1]) {
                try {
                    jsonData = JSON.parse(jsonPart[1]);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else jsonData = res.data;

            console.log('@@ callChatGPTAPI => ', jsonData);
            return jsonData;
        } else {
            if (
                res.error.errCode === 'CHATGPT_ERR_00' ||
                res.error.errCode === 'DATA_ERR_00'
            ) {
                console.log('@@ err => ', res.error.errCode);
                // 오류 처리
                setShowLoading(false);
            }
            console.log('@@ err => ', res.error);
        }
    };

    const callAPIFalaiforBackground = async (generatedStory) => {
        setLoadingFlag('bg');

        console.log('@@ generatedStory => ', generatedStory);
        let prompts = [];
        for (const promptJson of generatedStory) {
            let prompt = {
                user_id: USER_ID,
                story_id: selectedBookId,
                background_prompt: promptJson.background_prompt,
                character_prompt: promptJson.character_prompt,
                image_size: 'landscape_16_9',
                num_images: 1,
            };
            prompts.push(prompt);
        }
        console.log('@@ prompts => ', prompts);
        let res = await callFalaiAPIforBackground(selectedBookId, prompts, USER_ID, 'storybook_story');
        if (res.success) {
            console.log('@@ callFalaiAPIforBackground => ', res.data);

            generatedStory.forEach((newstory, index) => {
                let result = {
                    page_id: newstory.page_id,
                    page_content: newstory.page_content,
                    background: res.data[index][0].url,
                };
                console.log('result in generatedStory forEach: ', result);
                story.push(result);
            });
            console.log(story);

            setStory(story);
            setIndex(branchInfo.branchNum);
            controlLoading(false);

            if (audioRef.current) {
                audioRef.current.play().catch((error) => {
                    console.error('오디오 재생에 실패했습니다.', error);
                });
            }
        } else {
            if (
                res.error.errCode === 'CHATGPT_ERR_00' ||
                res.error.errCode === 'DATA_ERR_00' ||
                res.error.errCode === 'Falai_ERR_00'
            ) {
                console.log('@@ err => ', res.error.errCode);
                // 오류 처리
                setShowLoading(false);
                setShowBranchModal(true);
            }
            console.log('@@ err => ', res.error);
        }
    };

    const regenerateBackground = async (generatedPrompt) => {
        console.log('@@ generatedPrompt => ', generatedPrompt);
        let prompts = [
            {
                user_id: USER_ID,
                story_id: selectedBookId,
                background_prompt: generatedPrompt.background_prompt,
                character_prompt: generatedPrompt.character_prompt,
                image_size: 'landscape_16_9',
                num_images: 1,
            },
        ];
        console.log('@@ prompts => ', prompts);
        let res = await callFalaiAPIforBackground(selectedBookId, prompts, USER_ID, 'storybook_rebackground');
        if (res.success) {
            console.log('@@ callFalaiAPIforBackground => ', res.data);

            let urls = [];
            res.data[0].forEach((resJson) => {
                urls.push(resJson.url);
            });

            setRegenerateImage(urls);

            setGenerateEnd(true);
            setShowImageModal(true);
        } else {
            if (
                res.error.errCode === 'CHATGPT_ERR_00' ||
                res.error.errCode === 'DATA_ERR_00' ||
                res.error.errCode === 'Falai_ERR_00'
            ) {
                console.log('@@ err => ', res.error.errCode);
                // 오류 처리
                setGenerateEnd(true);
            }
            console.log('@@ err => ', res.error);
            setGenerateEnd(true);
        }
    };

    useEffect(() => {
        const sentences =
            story[index] && story[index].page_content
                ? story[index].page_content.split('\n')
                : [];
        setcontentText(sentences.join(' '));
    }, [index, story]);

    return (
        <>
            {showInfoModal && (
                <InfoModal
                    onConfirm={handleAlertConfirm}
                    title={Constants.ALERT.warningTitle}
                    tips={Constants.ALERT.blackScreenTips}
                />
            )}
            <section className="stories-section">
                <audio
                    ref={audioRef}
                    src="/audio/twinkle-storybook.mp3"
                />
                <figure className="stories-bg">
                    <img
                        src={story[index].background ?? ''}
                        alt="bg-img"
                    />
                </figure>
                {showChoiceBtns && (
                    <div className="choice-btns">
                        <div className="left-btn">
                            <div className="ballon">
                                " 스토리를 다시 선택하고싶다면? "
                            </div>
                            <div
                                className="btn go-choice-modal"
                                onClick={handleReSelect}
                            >
                                <figure>
                                    <img
                                        src="../images/arrow-left.svg"
                                        alt="다시 선택하기 아이콘"
                                    />
                                </figure>
                                <span>다시 선택하기</span>
                            </div>
                        </div>

                        <div className="right-btn">
                            <div className="ballon right">
                                " 그림을 바꾸고 싶나요? "
                            </div>
                            <div
                                className="btn open-image-modal"
                                onClick={handleChangeBackground}
                            >
                                <figure>
                                    <img
                                        src="../images/change.svg"
                                        alt="이미지 바꾸기 아이콘"
                                    />
                                </figure>
                                <span>이미지 바꾸기</span>
                            </div>
                        </div>
                    </div>
                )}
                {/* 2023.11.28 추가 | 처음으로 돌아가기, 이미지바꾸기 버튼 */}
                {!showFinalModal && !showBranchModal && (
                    <>
                        {index !== 0 && (
                            <ArrowButton
                                onClick={() => handleButtonClick('left')}
                                direction="left"
                                className="story-btn prev-btn"
                            />
                        )}
                        <ArrowButton
                            onClick={() => handleButtonClick('right')}
                            direction="right"
                            className="story-btn next-btn"
                        />
                    </>
                )}

                <ul className="story-contents">
                    {sentences.map((sentence, i) => (
                        <li
                            key={i}
                            className="origin-text"
                        >
                            {sentence.trim()}
                            <div className="text-stroke">{sentence.trim()}</div>
                        </li>
                    ))}
                </ul>
                <TextToSpeech
                    text={contentText}
                    speechState={speechState}
                    onChange={handleSpeechState}
                />
                <div className="numbering">{index + 1}</div>

                {showLoading && (
                    // <Loading
                    //     title={loadingTitle}
                    //     end={generateEnd}
                    //     flag={loadingFlag}
                    //     onEnd={() => setShowLoading(false)}
                    // />
                    <BookLoading
                        title={loadingTitle}
                        end={generateEnd}
                        flag={loadingFlag}
                        onEnd={() => setShowLoading(false)}
                        timeCount={loadingTime}
                    />
                )}
                {showConfirmModal && (
                    <ConfirmModal
                        title={storyTitle}
                        onClose={handleCloseConfirmModal}
                    />
                )}
                {/* {showChangeModal && (
                    <Loading
                        title={loadingTitle}
                        end={generateEnd}
                        flag={loadingFlag}
                        onEnd={() => setShowLoading(false)}
                    />
                )} */}
                {showBackwardModal && (
                    <BackwardModal
                        onBack={handleBackward}
                        onClose={() => setShowBackwardModal(false)}
                    />
                )}

                {showBranchModal && (
                    <BranchModal
                        onChoice={handleChoice}
                        // onClose={() => setShowBranchModal(false)}
                        onClose={handleBackBranchModal}
                        choiceData={branchInfo}
                    />
                )}
                {showImageModal && (
                    <BackgroundModal
                        modalTitle={'새로운 배경그림을 선택해주세요.'}
                        propImg={regenerateImage}
                        originImg={story[index].background}
                        onImageSelect={handleBackgroundImageChange}
                        onRegenerate={handleChangeBackground}
                        onClose={() => {
                            setShowImageModal(false);
                        }}
                    />
                )}
                {showFinalModal && (
                    <FinalModal
                        contentList={story}
                        story_id={selectedBookId}
                        branchNum={branchInfo.branchNum}
                        onClose={() => setShowFinalModal(false)}
                    />
                )}
            </section>
            <LandScapeModal />
        </>
    );
};

export default loginSessionCheck(Making);
