import { createContext, useContext, useState } from 'react';
// React에서 상태 관리와 컨텍스트를 사용하기 위해 필요한 함수와 객체들을 불러옴

const SelectedBookContext = createContext();
// createContext 함수를 사용하여 새로운 컨텍스트를 생성
// 이 컨텍스트는 선택된 책의 ID를 전역적으로 관리하기 위한 용도로 사용

export const SelectedBookProvider = ({ children }) => {
  const [STORYID, setSelectedStoryId] = useState(null);
  // 컨텍스트에서 관리할 상태인 selectedBookId를 생성하고, 초기값을 null로 설정

  const setStoryId = (bookId) => {
    setSelectedStoryId(bookId);
  };
  // selectedBookId 상태를 업데이트하는 함수인 setBookId를 정의

  return (
    <SelectedBookContext.Provider value={{ STORYID, setStoryId }}>
      {children}
      {/* 이 컴포넌트가 감싸고 있는 자식 컴포넌트들을 렌더링 */}
    </SelectedBookContext.Provider>
  );
  // 자식 컴포넌트에 제공할 값으로 selectedBookId와 setBookId를 객체 형태로 묶어서 제공
};
// 선택된 책의 ID를 관리하는 컨텍스트를 제공하는 컴포넌트를 정의
// 이 컴포넌트는 selectedBookId 상태와 이를 업데이트하는 setBookId 함수를 제공하며, 자식 컴포넌트들을 감싸고 있는 children을 받아 렌더링

export const useSelectedStoryID = () => {
  return useContext(SelectedBookContext);
  // 현재 컨텍스트의 값을 반환하여 해당 값들을 사용할 수 있게 합니다. 이 훅을 사용하면 다른 컴포넌트에서 선택된 책의 ID를 읽고 업데이트할 수 있습니다.
};
// 텍스트에서 제공된 SelectedBookContext를 사용하여 선택된 책의 ID를 읽고 업데이트하는데 필요한 훅인 useSelectedBook을 정의
