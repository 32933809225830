import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import '../styles/SeriesBookItems.scss';
import { useNavigate } from 'react-router-dom';
import TextToSpeech from './TextToSpeech';

const SeriesBookItems = ({ bookList, pageName }) => {
    console.log(`bookList : ${bookList}`);
    console.log(`pageName : ${pageName}`);

    const navigate = useNavigate();

    const groupedBooks = useMemo(
        () =>
            bookList.reduce((acc, book) => {
                const { series_name } = book;
                if (!acc[series_name]) {
                    acc[series_name] = [];
                }
                acc[series_name].push(book);
                return acc;
            }, {}),
        [bookList]
    );

    // 2024-02-14 | 각 책 아이템마다 음성 재생 상태를 관리하기위한 state
    const [speechState, setSpeechState] = useState(bookList.map(() => false));

    const goReadingPage = (books, i) => {
        console.log('book+_id : ', books.id);
        console.log('book+i : ', i);
        console.log('bookList[i] : ', books);
        localStorage.setItem('book_id', books.id);
        // navigate('/reading', {state: { contents_json: bookList[0].contents_json, pageName: pageName}});
        navigate('/reading', {
            state: {
                bookList: books,
                pageName: pageName,
                bookID: books.id,
            },
        });
    };

    const printDate = (date) => {
        console.log(date);
        const result_date = date.split('T');
        return result_date[0];
    };

    // 음성 재생 상태를 토글
    const toggleSpeechState = (index) => {
        const update_speech_states = [...speechState];
        update_speech_states[index] = !update_speech_states[index];
        setSpeechState(update_speech_states);
    };

    return (
        <>
            {Object.entries(groupedBooks).map(([seriesName, books]) => (
                <div className="series-wrapper">
                    <div
                        key={seriesName}
                        className="book-item-series"
                    >
                        <h3>{seriesName}</h3>
                        <ul className="book-list-series">
                            {books.map((book, i) => (
                                <li
                                    className="book-item-series"
                                    key={book.id}
                                    onClick={() => goReadingPage(book, i)}
                                >
                                    <Link>
                                        <figure className="book-cover">
                                            <img
                                                src={book.bookcover}
                                                alt={book.title}
                                            />
                                        </figure>
                                        <figure className="book-bottom">
                                            <img
                                                src="/images/book-cover-bottom.svg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="book-name-container">
                                            <h3 className="book-title">
                                                {book.title}
                                                <div className="book-title-stroke">
                                                    {book.title}
                                                </div>
                                            </h3>
                                        </div>
                                    </Link>
                                    <TextToSpeech
                                        text={book.title}
                                        speechState={!!speechState[book.id]}
                                        onChange={() =>
                                            toggleSpeechState(book.id)
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};

export default SeriesBookItems;
