import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const FromMagickid = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const USER_ID = queryParams.get('userID');
        const USER_NAME = queryParams.get('userName');
        const LOGIN_TIME = queryParams.get('loginTime');
        const PAGE = queryParams.get('page');

        localStorage.setItem('userID', USER_ID);
        localStorage.setItem('userName', USER_NAME);
        localStorage.setItem('loginTime', LOGIN_TIME);

        console.log('userID: ', USER_ID);
        console.log('PAGE: ', PAGE);

        if (USER_ID && USER_NAME && LOGIN_TIME && PAGE === 'selecting') {
            navigate('/selecting');
        } else if (USER_ID && USER_NAME && LOGIN_TIME && PAGE === 'storyland') {
            navigate('/storyland');
        } else if (USER_ID && USER_NAME && LOGIN_TIME && PAGE === 'storyland-reading') {
            navigate('/reading');
        } else {
            navigate('/logout');
        }
    }, [navigate, location.search]);

    return null;
};

export default FromMagickid;
