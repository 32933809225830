import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [USER, setUser] = useState(null); //NOTE: json {userId}
  const [IS_AUTH, setIS_AUTH] = useState(false);

  const LOGIN = (userData) => {
    setUser(userData);
    setIS_AUTH(true);
  };

  const LOGOUT = () => {
    setUser(null);
    setIS_AUTH(false);
  };

  return (
    <AuthContext.Provider value={{ USER, LOGIN, LOGOUT, IS_AUTH }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
// 2023.11.23 추가 | src 폴더에 AuthContext.js 추가
// 로그인 상태를 관리할 Context
