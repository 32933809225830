import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Header.scss';
import { useSelectedStoryID } from '../SelectedBookContext';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // 2024.01.04 | 랜덤로고 state
    const [logoImage, setLogoImage] = useState();
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

    const toggleHamburgerMenu = () => {
        setShowHamburgerMenu(!showHamburgerMenu);
    };

    const USER_NAME = localStorage.getItem('userName') ?? '';
    const { STORYID } = useSelectedStoryID();

    const isStoriesPage = location.pathname === `/making/${STORYID}`;
    const isReadingPage = location.pathname === `/reading`;
    const headerClassName = `${isStoriesPage || isReadingPage ? 'hovered' : ''
        }`;
    const [isMouseOver, setIsMouseOver] = useState(false);
    const handleMouseEnter = () => setIsMouseOver(true);
    const handleMouseLeave = () => setIsMouseOver(false);

    const logos = useMemo(
        () => [
            '/images/logo-play-1.svg',
            '/images/logo-play-2.svg',
            '/images/logo-play-3.svg',
        ],
        []
    );

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * logos.length);
        console.log('Selected logo index:', randomIndex); // 디버깅을 위한 로그
        setLogoImage(logos[randomIndex]);
    }, [location]);

    const goMain = () => {
        // const params = { userID: USER_ID, userName: USER_NAME };
        // console.log('header => ', params);
        // navigate(`/?userID=${USER_ID}&userName=${USER_NAME}`);
    };

    return (
        <>
            <header
                id="header"
                className={headerClassName}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="inner">
                    <h1
                        className="logo"
                        onClick={goMain}
                    >
                        <img
                            src={logoImage}
                            alt="main-logo"
                        ></img>
                    </h1>
                    <div className="user-container">
                        <span
                            onClick={() => {
                                if (!isMouseOver) return;
                                navigate(`/storyland`);
                            }}
                            className="storyland-btn"
                        >
                            스토리랜드 🎈
                        </span>
                        <span
                            className="library-btn"
                            onClick={() => {
                                if (!isMouseOver) return;
                                navigate(`/myStorybook`);
                            }}
                        >
                            나만의 서재 ⭐
                        </span>
                        <span
                            className="back-magickid-btn"
                            onClick={() => {
                                if (!isMouseOver) return;
                                window.close();
                            }}
                        >
                            <figure>
                                <img
                                    src={'/images/back-magickid-3.svg'}
                                    alt=""
                                />
                            </figure>
                        </span>
                        <span className="user-id">
                            {USER_NAME.length > 3 && <>
                                {USER_NAME}
                                <span className="sir"> 님</span>
                            </>}

                        </span>
                    </div>
                    {/* -------------모바일 메뉴------------- */}
                    <div className="hamburger-mobile">
                        <button onClick={toggleHamburgerMenu}>
                            <figure>
                                <img
                                    src={'/images/menu.svg'}
                                    alt=""
                                />
                            </figure>
                            <span>MENU</span>
                        </button>
                    </div>
                    {showHamburgerMenu && (
                        <div className="ham-fixed">
                            <div
                                className="close-m"
                                onClick={() => {
                                    setShowHamburgerMenu(false);
                                }}
                            >
                                <button>
                                    <img
                                        src={'/images/close.png'}
                                        alt="close-btn"
                                    />
                                </button>
                            </div>
                            <p className="name-container">
                                {USER_NAME}
                                <span className="sir"> 님</span>
                            </p>
                            <ul className="navi">
                                <li
                                    className="storyland"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        setShowHamburgerMenu(false);
                                        navigate(`/storyland`);
                                    }}
                                >
                                    스토리랜드 🎈
                                </li>
                                <li
                                    className="my-library"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        setShowHamburgerMenu(false);
                                        navigate(`/myStorybook`);
                                    }}
                                >
                                    나만의 서재 ⭐
                                </li>
                                <li
                                    className="back-magic-kid"
                                    onClick={() => {
                                        if (!isMouseOver) return;
                                        window.close();
                                    }}
                                >
                                    매직포유로 돌아가기
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;
