import './Fonts/Font.css';
import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import { GlobalStyles } from './styled';
import { AuthProvider } from './AuthContext';
import { SelectedBookProvider } from './SelectedBookContext';
import MyStorybook from './pages/MyStorybook';
import Reading from './pages/Reading';
import Making from './pages/Making';
import Selecting from './pages/Selecting';
import StoryLand from './pages/StoryLand';
import BranchModal from './components/BranchModal';
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ReadingbyAIMagkickid from './pages/ReadingbyAIMagickid';
import ExpiredModal from './components/ExpiredModal';
import FromMagickid from './pages/FromMagickid';
import Heart from './components/Heart';
import HeaderController from './components/HeaderController';

function App() {
    const [hearts, setHearts] = useState([]);

    const handleHeartClick = (event) => {
        const x = event.pageX;
        const y = event.pageY;
        const now = Date.now();
        setHearts((prevHearts) => [
            ...prevHearts,
            { id: now, left: x, top: y },
        ]);
        setTimeout(() => {
            setHearts((current_hearts) =>
                current_hearts.filter((heart) => heart.id !== now)
            );
        }, 500);
    };

    useEffect(() => {
        document.addEventListener('click', handleHeartClick);

        //NOTE: 우클릭방지 처리
        const handleRightClick = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', handleRightClick);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            document.removeEventListener('contextmenu', handleRightClick);
        };
    }, []);

    const RenderHeader = () => {
        const location = useLocation(); // 현재 위치 가져오기

        // 'ReadingbyAIMagkickid' 페이지에서는 Header를 숨김
        if (location.pathname === '/reading-aimagickid') {
            return null;
        }

        // 그 외의 경우에는 Header를 표시
        return <Header />;
    };

    return (
        <AuthProvider>
            <SelectedBookProvider>
                <Router>
                    <GlobalStyles />
                    {/* <Header /> */}
                    <HeaderController />
                    {hearts.map(({ id, left, top }) => (
                        <Heart
                            key={id}
                            left={left}
                            top={top}
                        />
                    ))}
                    {/* <RenderHeader /> */}
                    <Routes>
                        <Route
                            path="/myStorybook"
                            element={<MyStorybook />}
                        />
                        <Route
                            path="/reading"
                            element={<Reading />}
                        />
                        <Route
                            path="/reading-aimagickid"
                            element={<ReadingbyAIMagkickid />}
                        />
                        <Route
                            path="/making/:selectedBookId"
                            element={<Making />}
                        />
                        <Route
                            path="/branch"
                            element={<BranchModal />}
                        />
                        <Route
                            path="/storyland"
                            element={<StoryLand />}
                        />
                        <Route
                            path="/selecting"
                            element={<Selecting />}
                        />
                        <Route
                            path="/loading"
                            element={<Loading />}
                        />
                        <Route
                            path="/logout"
                            element={<ExpiredModal />}
                        />
                        <Route
                            path="/check"
                            element={<FromMagickid />}
                        />
                    </Routes>
                    {/* <Footer /> */}
                </Router>
            </SelectedBookProvider>
        </AuthProvider>
    );
}

export default App;
